<template>
  <div>
    <section class="section is-main-section">
      <div class="columns">
        <div class="column is-one-third">
          <card-component
            title="1. Select the datasets to integrate"
            class="tile is-child"
            :displayHelp="$options.name"
            titleHelp="Combine target"
          >
            <validation-observer v-slot="{ handleSubmit }">
              <validation-provider
                v-slot="{ errors }"
                :rules= {required:true}
                :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
                name="dataset"
              >
                <dataset-select
                  ref="xdataset"
                  label="Predictor dataset (X)"
                  :dataset.sync="X"
                  v-model="X"
                  :withVarname="false"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                  icon="table"
                  pack="fa"
                />
              </validation-provider>
              <br />
              <validation-provider
                v-slot="{ errors }"
                :rules= {required:true}
                :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
                name="target"
              >
                <dataset-select
                  label="Target variable (to explain; Y)"
                  ref="ydataset"
                  :dataset.sync="Y"
                  :varname.sync="target"
                  v-model="target"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                  @select="option => selectVarname(option)"
                  pack="fa"
                  icon="crosshairs"
                  vartype="quali"
                  :minlevel=2
                  :minCatVariable="1"
                  :alldistinct="'no'"
                  labeltooltip="Categorical variables non-constant and non 'all distinct'"
              />
              </validation-provider>
              <hr />
              <div class="has-text-centered">
                <b-button rounded
                  class="is-primary button-shadow"
                  @click.prevent="handleSubmit(run)"
                >
                  <span class="icon is-small"><i class="fa fa-search"></i></span>
                  <span> Integrate </span>
                </b-button>
              </div>
            </validation-observer>
          </card-component>
        </div>
        <div class="column">
          <card-component title="2. Plots and summaries" />
          <error :type='"notifications"' />
          <card-no-result v-if="!hasResults"/>
          <div v-else>
            <card-component>
              <b-tabs @input="displayResult">
                <b-tab-item label="General information" icon="table" value="generalInfo" />
                <b-tab-item label="Venn diagram" icon-pack="icomoon" icon="venn" value="venn" />
                <b-tab-item label="Upset plot" icon-pack="icomoon" icon="upsetjs" value="upsetjs" />
              </b-tabs>
            </card-component>
            <card-component
              v-if="currentResult==='generalInfo'"
              :title="generalInfos[0].title"
            >
              <general-info :data="generalInfos[0]"/>
            </card-component>
            <card-component
              v-if="currentResult==='generalInfo'"
              :title="generalInfos[1].title"
            >
              <general-info :data="generalInfos[1]"/>
            </card-component>
            <card-component v-show="currentResult==='venn'">
              <venn ref="venn" :data="venn"/>
            </card-component>
            <card-component v-if="currentResult==='upsetjs'">
              <upset-js :data="upsetJS"/>
            </card-component>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RCombineTarget',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'venn': () => import('@/components/rresults/Venn.vue'),
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'upset-js': () => import('@/components/rresults/UpsetJs.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'general-info': () => import('@/components/rresults/GeneralInfo.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  props: {
    minNumericVariable: {
      type: Number,
      default: 0
    },
    minSample: {
      type: Number,
      default: 0
    },
    nbDataset: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      X: null,
      Y: null,
      target: '',
      currentResult: 'generalInfo'
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  watch: {
    analysisObj: function () {
      this.initFormAndResults()
    }
  },
  computed: {
    params: function () {
      return {
        'func_name': 'r_combine_target',
        'X': this.X,
        'Y': this.Y,
        'target': this.target
      }
    },
    allDatasets: function () {
      return this.workspaceStore.datasets
    }
  },
  methods: {
    displayResult: function (value) {
      this.currentResult = value
      if (value === 'venn') {
        this.$refs.venn.render()
      }
    },
    beforeRun: function () {
      this.$emit('reset')
    },
    afterRun: function (data) {
      this.currentResult = 'generalInfo'
      if (this.hasResults) {
        this.$emit('is-combined', {
          'combinedObjectName': data.ObjectName,
          'combinedDatasets': [this.X, this.Y],
          'combinedDatasetsUserNames': [this.allDatasets.filter(obj => obj.object_name === this.X)[0].user_name, this.allDatasets.filter(obj => obj.object_name === this.Y)[0].user_name],
          'xNature': this.allDatasets.filter(obj => obj.object_name === this.X)[0].meta.nature,
          'xLogt': this.allDatasets.filter(obj => obj.object_name === this.X)[0].meta.logt
        })
      }
    },
    selectVarname: function (value) {
      this.target = value.name
    }
  },
  mounted () {
    if (this.analysisObj !== null) {
      if (this.analysisObj.origin_dataset.length > 1) {
        this.datasets = []
        this.analysisObj.origin_dataset.forEach(d => {
          this.datasets.push(this.allDatasets.filter(obj => obj.object_name === d)[0])
        })
      }
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
